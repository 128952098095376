import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import moment from 'moment-timezone'

import { openModal } from 'mednet-cns/src/reducers/modal'
import {
  fetchAvailableSpecialties,
  FETCH_AVAILABLE_SPECIALTIES,
  createDigest,
  CREATE_DIGEST,
  deleteDigest,
  deleteDigestUpdate,
  ADD_DIGEST_UPDATE,
  fetchTodayDigest,
  FETCH_TODAY_DIGEST,
  DELETE_DIGEST,
  suspendDigest,
  resumeDigest,
  RESUME_DIGEST,
  SUSPEND_DIGEST,
  addDigestUpdate,
  setDigestOrder,
  setPrimaryTopic,
  setDigestSubject,
  SET_DIGEST_ORDER,
  DELETE_DIGEST_UPDATE,
  SPLIT_DIGEST_INTO_TWO_COHORTS,
  mergeTodayCohorts,
  MERGE_TODAY_DIGEST_COHORTS,
  setTemplateFlag,
  setIncludeAskExpertFlag,
  splitIntoTwoCohorts,
} from 'mednet-cns/src/reducers/digest'
import { FETCH_USER_WITH_PERMISSIONS } from 'mednet-cns/src/reducers/user'
import { fetchNotificationUsage } from 'mednet-cns/src/reducers/question'

import {
  DELETE_DIGEST_MODAL,
  DELETE_DIGEST_UPDATE_MODAL,
  DELETE_DIGEST_QUESTION_MODAL,
  EMAIL_PREVIEW_MODAL,
  SPLIT_DIGEST_COHORT_MODAL,
} from 'mednet-util/src/constants/modal'
import { Page } from 'pharmacy/src/display/page'
import { getRequest } from 'mednet-cns/src/api/v1'
import { makeAssetURL } from 'mednet-util/src/router'
import {
  digestCohorts,
  digestCohortsLabel,
  digestStatus,
} from 'mednet-util/src/constants/digest'
import { actionIdByName } from 'mednet-util/src/constants/questionUpdate'

import {
  Header1,
  Header2,
  Header3,
  Header4,
  Subtitle1,
  Body1,
  Subtitle4,
} from 'pharmacy/src/typography'
import { ContainedSelect, SimpleSelect } from 'pharmacy/src/input/select'
import { CoverLoader, StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Button } from 'pharmacy/src/input/button'
import { ConfirmationModal } from 'pharmacy/src/display/modal'
import SidePanel from 'pharmacy/src/display/sidePanel/sidePanel'
import { SimpleDropdownIndicator } from 'pharmacy/src/input/select/components'
import { Icon } from 'pharmacy/src/display/icon'

import { fetchUpdateInNotificationUsage } from 'mednet-cns/src/reducers/questionUpdate'

import classNames from 'classnames'

import { SearchQuestionUpdatesPage } from '../searchQuestionUpdates'
import QuestionUpdateCard from '../../components/questionUpdateCard/questionUpdateCard'
import QuestionShortTitleModal from '../../components/questionShortTitleModal/questionShortTitleModal'
import EmailPreviewModal from '../../components/emailPreviewModal/emailPreviewModal'
import EmailAutoSubject from '../../components/emailAutoSubject/emailAutoSubject'

import css from './manageDigest.scss'
import SplitCohortModal, { SPLIT_METHODS } from './splitCohortModal'
import DigestDescriptionField from './digestDescriptionField'
import DigestBooleanField from './digestBooleanField'

const SHOW_SPLIT_OPTIONS = false

class ManageDigestPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isPanelOpened: false,
      selectedSpecialtyId: props.preselectedSpecialtyId,
      selectedCohort: undefined,
    }
  }

  componentDidMount() {
    this.props.fetchAvailableSpecialties()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      availableSpecialtiesIsLoaded,
      userData,
      userDataIsLoaded,
      fetchNotificationUsage,
      fetchUpdateInNotificationUsage,
      preselectedSpecialtyId,
      allUpdateNotifications,
      fetchTodayDigestIsLoading,
    } = this.props
    const specialtyId = this.state.selectedSpecialtyId
    const digest = this.getDigest()
    const questions = this.getQuestionsDetails()
    const updates = this.getUpdates()

    if (
      (!prevProps.userDataIsLoaded &&
        userDataIsLoaded &&
        availableSpecialtiesIsLoaded) ||
      (!prevProps.availableSpecialtiesIsLoaded &&
        availableSpecialtiesIsLoaded &&
        userDataIsLoaded)
    ) {
      const selectedSpecialtyId = preselectedSpecialtyId || userData.specialtyId
      this.props.fetchTodayDigest(selectedSpecialtyId)
      this.setState({
        selectedSpecialtyId,
        selectedCohort: undefined,
      })
    }

    const questionIdsToFetch = questions
      .filter(
        (question) =>
          !question.notifications || !question.notifications[specialtyId]
      )
      .map((question) => question.questionId)

    const allUpdateNotificationsIds = _.keys(
      allUpdateNotifications[specialtyId] || {}
    )

    const updateIdsToFetch = updates
      .filter(
        (update) =>
          !allUpdateNotificationsIds.includes(
            update.questionUpdateId.toString()
          )
      )
      .map((update) => update.questionUpdateId)

    questions.forEach((question) => {
      const update = question.updates.find(
        (update) => update.actionId === actionIdByName.POLL_APPROVED
      )
      if (
        update &&
        !allUpdateNotificationsIds.includes(
          update.questionUpdateId.toString()
        ) &&
        !updateIdsToFetch.includes(update.questionUpdateId.toString())
      ) {
        updateIdsToFetch.push(update.questionUpdateId.toString())
      }
    })

    if (specialtyId && digest?.digest_id) {
      if (questionIdsToFetch.length) {
        fetchNotificationUsage(specialtyId, questionIdsToFetch, {
          digestId: digest.digest_id,
        })
      }
      if (updateIdsToFetch.length) {
        fetchUpdateInNotificationUsage(specialtyId, updateIdsToFetch, {
          digestId: digest.digest_id,
        })
      }
    }

    // This is needed to update cohort filter after digests are fetched
    if (
      prevProps.fetchTodayDigestIsLoading &&
      !fetchTodayDigestIsLoading &&
      digest?.cohort &&
      !prevState.selectedCohort
    ) {
      this.setState({
        selectedCohort: digest.cohort,
      })
    }
  }

  handlePanelToggle = (isPanelOpened) =>
    this.setState({
      isPanelOpened,
    })

  handleChangeSpecialty = (option) => {
    const selectedSpecialtyId = option.value
    const selectedSpecialtyTitle = option.label
    this.props.fetchTodayDigest(selectedSpecialtyId)
    this.setState({
      selectedSpecialtyTitle,
      selectedSpecialtyId,
      selectedCohort: undefined,
    })
  }

  handleChangeCohort = (option) => {
    this.setState({
      selectedCohort: option.value,
    })
  }

  handleCreate = () => {
    const { selectedSpecialtyId } = this.state
    const { createDigest } = this.props

    createDigest(selectedSpecialtyId)
  }

  handleDelete = ({ closeModal }) => {
    const { deleteDigest } = this.props
    const { selectedSpecialtyId } = this.state
    const digestId = this.getDigest()?.digest_id

    deleteDigest(digestId, () => {
      this.props.fetchTodayDigest(selectedSpecialtyId)
      this.setState({
        selectedCohort: undefined,
      })
    })
    closeModal()
  }

  handleDeleteUpdate = ({ closeModal, updateId, questionId }) => {
    const { deleteDigestUpdate } = this.props
    const { selectedSpecialtyId } = this.state
    const digestId = this.getDigest()?.digest_id

    const toDelete = _.isArray(updateId) ? updateId : [updateId]

    deleteDigestUpdate(
      selectedSpecialtyId,
      digestId,
      toDelete,
      questionId,
      this.afterUpdateRemoved
    )
    closeModal()
  }

  handleDeleteQuestion = ({ closeModal, questionId }) => {
    const { deleteDigestUpdate } = this.props
    const { selectedSpecialtyId } = this.state
    const digestId = this.getDigest()?.digest_id
    const updates = this.getUpdates()
    const updateIdsToRemove = updates
      .filter((update) => update.questionId === questionId)
      .map((update) => update.questionUpdateId)

    deleteDigestUpdate(
      selectedSpecialtyId,
      digestId,
      updateIdsToRemove,
      questionId,
      this.afterUpdateRemoved
    )
    closeModal()
  }

  setNewQuestionPosition = (questionId, destinationIndex) => {
    const { setDigestOrder } = this.props
    const digestId = this.getDigest()?.digest_id
    const digestQuestions = this.getDigestQuestions()

    const question = _.find(
      digestQuestions,
      (question) => question.questionId === questionId
    )
    const newOrder = digestQuestions.filter(
      (question) => question.questionId !== questionId
    )
    newOrder.splice(destinationIndex, 0, question)

    setDigestOrder(digestId, newOrder)
  }

  handleMoveQuestionDown = (questionId) => {
    const digestQuestions = this.getDigestQuestions()

    const index = _.findIndex(
      digestQuestions,
      (question) => question.questionId === questionId
    )

    if (index < digestQuestions.length - 1) {
      this.setNewQuestionPosition(questionId, index + 1)
    }
  }

  handleMoveQuestionUp = (questionId) => {
    const digestQuestions = this.getDigestQuestions()

    const index = _.findIndex(
      digestQuestions,
      (question) => question.questionId === questionId
    )

    if (index > 0) {
      this.setNewQuestionPosition(questionId, index - 1)
    }
  }

  handleDragEnd = (result) => {
    const questionId = Number(result.draggableId)
    const destinationIndex = _.get(result, 'destination.index', null)
    const sourceIndex = _.get(result, 'source.index', null)

    if (
      destinationIndex === sourceIndex ||
      destinationIndex === null ||
      sourceIndex === null
    )
      return

    this.setNewQuestionPosition(questionId, destinationIndex)
  }

  setSubject = _.debounce((subject) => {
    const { setDigestSubject } = this.props
    const digestId = this.getDigest()?.digest_id
    setDigestSubject(digestId, subject)
  }, 1000)

  toggleDigestStatus = () => {
    const { suspendDigest, resumeDigest } = this.props
    const digestId = this.getDigest()?.digest_id
    const digest = this.getDigest()

    if (digest.status === digestStatus.STATUS_SCHEDULED) {
      suspendDigest(digestId, 'Digest suspended by moderator')
    } else {
      resumeDigest(digestId)
    }
  }

  handleSplitIntoTwoCohorts = () => {
    const { openSplitCohortModal } = this.props
    const digestId = this.getDigest()?.digest_id

    if (digestId) {
      if (SHOW_SPLIT_OPTIONS) {
        openSplitCohortModal(digestId, () => {
          this.setState({
            selectedCohort: digestCohorts.COHORT_A,
          })
        })
      } else {
        this.props.splitIntoTwoCohorts(digestId, SPLIT_METHODS.RANDOM, () => {
          this.setState({
            selectedCohort: digestCohorts.COHORT_A,
          })
        })
      }
    }
  }

  handleMergeSpecialtyTodayCohorts = () => {
    const { mergeTodayCohorts } = this.props

    mergeTodayCohorts(this.state.selectedSpecialtyId, () => {
      this.setState({
        selectedCohort: undefined,
      })
    })
  }

  afterUpdateAdded = () => {
    const { resumeDigest } = this.props
    const digestId = this.getDigest()?.digest_id
    const digestQuestions = this.getDigestQuestions()
    const digest = this.getDigest()

    if (
      digestQuestions &&
      digestQuestions.length &&
      digest.status === digestStatus.STATUS_SUSPENDED
    ) {
      resumeDigest(digestId)
    }
  }

  afterUpdateRemoved = () => {
    const { suspendDigest } = this.props
    const digestId = this.getDigest()?.digest_id
    const digestQuestions = this.getDigestQuestions()
    const digest = this.getDigest()

    if (
      digestQuestions &&
      !digestQuestions.length &&
      digest.status === digestStatus.STATUS_SCHEDULED
    ) {
      suspendDigest(digestId, 'Moderator removed all updates from digest')
    }
  }

  getDigest = () => {
    const { selectedSpecialtyId, selectedCohort } = this.state
    const { digests } = this.props

    return digests[selectedSpecialtyId]?.find(
      (digest) => !selectedCohort || digest.cohort === selectedCohort
    )
  }

  getUpdates = (questionId) => {
    const { allUpdates } = this.props
    let digestQuestions = this.getDigestQuestions()

    if (!digestQuestions) {
      digestQuestions = []
    } else if (questionId) {
      digestQuestions = digestQuestions.filter(
        (question) => question.questionId === questionId
      )
    }

    return _.flatMap(digestQuestions, (question) => question.updates).map(
      (updateId) => allUpdates[updateId]
    )
  }

  getDigestQuestions = () => {
    const digest = this.getDigest()
    return digest?.questions
  }

  getQuestionsDetails = () => {
    const digestQuestions = this.getDigestQuestions()

    const { allQuestions } = this.props

    if (!digestQuestions) return []

    return digestQuestions.map(({ questionId }) => allQuestions[questionId])
  }

  getSpecialtyTitle = () => {
    const { selectedSpecialtyId, selectedSpecialtyTitle } = this.state
    const { availableSpecialties } = this.props

    return selectedSpecialtyTitle
      ? selectedSpecialtyTitle
      : availableSpecialties[selectedSpecialtyId].specialty
  }

  renderStats = () => {
    const updates = this.getUpdates()
    const digest = this.getDigest()

    const noAnswerUpdates = updates.filter(
      (update) => update.actionId === actionIdByName.ANSWER
    ).length
    const noPollUpdates = updates.filter(
      (update) => update.actionId === actionIdByName.POLL_APPROVED
    ).length

    return (
      <div className={css.stats}>
        <Body1>
          <span className={css.line}>
            {`${noAnswerUpdates} answer${noAnswerUpdates > 1 ? 's' : ''}`}
          </span>{' '}
          <span className={css.line}>
            {`${noPollUpdates} poll${noPollUpdates > 1 ? 's' : ''}`}
          </span>
        </Body1>
        {digest.status !== digestStatus.STATUS_SCHEDULED ||
        !digest.summary ? null : (
          <Body1>
            <span className={css.line}>
              {`${digest.summary.notifications.notificationsNo} notifications to`}
            </span>{' '}
            <span className={css.line}>
              {`send after ${digest.summary.notifications.firstSend} ET`}
            </span>
          </Body1>
        )}
      </div>
    )
  }

  renderUpdates = () => {
    const {
      addDigestUpdate,
      fetchTodayDigestIsLoading,
      addDigestModalIsLoading,
      createIsLoading,
      openDeleteUpdateModal,
      openDeleteQuestionModal,
      openSubjectsPreviewModal,
      deleteDigestIsLoading,
      setPrimaryTopic,
      setTemplateFlag,
      setIncludeAskExpertFlag,
    } = this.props
    const { selectedSpecialtyId } = this.state

    const digest = this.getDigest()
    const digestId = digest?.digest_id
    const updates = this.getUpdates()
    const questions = this.getQuestionsDetails()

    if (
      fetchTodayDigestIsLoading ||
      addDigestModalIsLoading ||
      deleteDigestIsLoading ||
      createIsLoading
    ) {
      return <StarLoader />
    }

    const currentTime = moment().tz('UTC')

    const currentHour = currentTime.hour()
    const currentMinute = currentTime.minutes()

    const generateDisabled =
      currentHour === 21 && currentMinute >= 0 && currentMinute < 15

    if (!digest) {
      return (
        <div className={css.createDigestWrapper}>
          <div className={css.importantWarningsContainer}>
            <Header4>
              Digest for the next day is generated and sent automatically
            </Header4>
          </div>
          <Button
            className={css.createDigestButton}
            type="neutral"
            icon={['far', 'plus']}
            onClick={this.handleCreate}
            isDisabled={generateDisabled}
          >
            {generateDisabled
              ? 'Autogenerating (9:00pm - 9:15pm [UTC])'
              : 'Create Digest Manually'}
          </Button>
        </div>
      )
    }

    if (!updates || updates.length === 0) {
      return (
        <div className={css.importantWarningsContainer}>
          <Header4>No updates added to digest</Header4>
          <Subtitle1>
            Digests without updates are automatically suspended and they
            won&apos;t be sent
          </Subtitle1>
        </div>
      )
    }

    const title = this.getSpecialtyTitle()

    return (
      <>
        {digest.status === digestStatus.STATUS_SCHEDULED ? null : (
          <div className={css.importantWarningsContainer}>
            <Header3>
              WARNING! This digest is suspended for the next day!
            </Header3>
          </div>
        )}
        <div className={css.subjectContainer}>
          <Header1 className={css.subjectInfoIconWrapper}>
            <Icon
              className={css.subjectInfoIcon}
              onClick={openSubjectsPreviewModal(digestId, selectedSpecialtyId)}
              icon="info-circle"
            />
          </Header1>
          <EmailAutoSubject
            onSubjectChange={this.setSubject}
            subject={digest.subject}
          />
        </div>
        <hr />
        <div>
          <DigestBooleanField
            digest={digest}
            fieldKey="in_template"
            fieldLabel="Use Template"
            submit={setTemplateFlag}
          />
          <DigestDescriptionField digest={digest} />
          <DigestBooleanField
            digest={digest}
            fieldKey="include_ask_expert"
            fieldLabel={'Include the "Ask an Expert" section'}
            submit={setIncludeAskExpertFlag}
          />
        </div>
        <hr />
        {this.renderStats()}
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="questions">
            {(provided) => (
              <ul
                className={css.draggable}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {questions.map((question, index) => {
                  const questionUpdates = this.getUpdates(question.questionId)
                  const overridePrimaryTopic =
                    digest.primaryTopics[questionUpdates[0].questionUpdateId]
                  let primaryTopic

                  if (overridePrimaryTopic) {
                    primaryTopic = overridePrimaryTopic
                  } else {
                    primaryTopic = question.primaryTopic
                      ? question.primaryTopic.topicId
                      : undefined
                  }

                  return (
                    <Draggable
                      key={question.questionId}
                      draggableId={question.questionId.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={css.draggableElement}
                        >
                          <QuestionUpdateCard
                            question={question}
                            updates={questionUpdates}
                            dragHandleProps={provided.dragHandleProps}
                            onMoveDown={this.handleMoveQuestionDown}
                            onMoveUp={this.handleMoveQuestionUp}
                            moveButtonsHidden={snapshot.isDragging}
                            onRemoveQuestion={openDeleteQuestionModal}
                            onRemoveUpdate={openDeleteUpdateModal}
                            onAddUpdate={addDigestUpdate(
                              selectedSpecialtyId,
                              digestId,
                              this.afterUpdateAdded
                            )}
                            primaryTopic={primaryTopic}
                            specialtyId={selectedSpecialtyId}
                            specialtyTitle={title}
                            onTopicClick={setPrimaryTopic(
                              digestId,
                              questionUpdates.map(
                                (update) => update.questionUpdateId
                              )
                            )}
                            className={css.questionUpdateCard}
                          ></QuestionUpdateCard>
                        </li>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  }

  renderButtons = () => {
    const {
      addDigestModalIsLoading,
      openDeleteModal,
      fetchTodayDigestIsLoading,
      deleteDigestIsLoading,
      toggleDigestIsLoading,
      availableSpecialtiesIsLoaded,
      userDataIsLoaded,
      addDigestUpdate,
      addDigestUpdateIsLoading,
      splitCohortIsLoading,
      mergeCohortsIsLoading,
      openPreviewModal,
    } = this.props
    const digest = this.getDigest()
    const digestId = digest?.digest_id
    const { selectedSpecialtyId } = this.state

    if (
      fetchTodayDigestIsLoading ||
      addDigestModalIsLoading ||
      deleteDigestIsLoading ||
      !digest ||
      !availableSpecialtiesIsLoaded ||
      !userDataIsLoaded
    ) {
      return null
    }

    const title = this.getSpecialtyTitle()

    const updates = this.getUpdates()

    return (
      <div className={css.actionsContainer}>
        <SidePanel
          buttonText="Add more"
          header={`Updates for ${title}`}
          onToggle={this.handlePanelToggle}
        >
          <SearchQuestionUpdatesPage
            specialtyId={selectedSpecialtyId}
            specialtyTitle={title}
            digestId={digest.digest_id}
            onAddUpdate={addDigestUpdate(
              digest.specialty_id,
              digest.digest_id,
              this.afterUpdateAdded
            )}
            updateIsLoading={addDigestUpdateIsLoading}
            usedUpdates={updates}
          />
        </SidePanel>
        <div>
          {Boolean(updates.length) && (
            <Button
              className={css.button}
              size="small"
              type="secondary"
              isLoading={mergeCohortsIsLoading || splitCohortIsLoading}
              icon={digest.cohort ? ['fas', 'user'] : ['fas', 'user-friends']}
              iconProps={{
                className: css.buttonIcon,
              }}
              onClick={
                digest.cohort
                  ? this.handleMergeSpecialtyTodayCohorts
                  : this.handleSplitIntoTwoCohorts
              }
            >
              {digest.cohort ? 'Merge cohorts' : 'Split into A/B cohorts'}
            </Button>
          )}
          <Button
            className={css.button}
            size="small"
            type="destructive"
            icon={['far', 'trash-alt']}
            iconProps={{
              className: css.buttonIcon,
            }}
            onClick={openDeleteModal}
          >
            Delete
          </Button>
          {!updates.length ? null : (
            <>
              <Button
                className={css.button}
                size="small"
                type="neutral"
                isLoading={toggleDigestIsLoading}
                icon={
                  digest.status === digestStatus.STATUS_SCHEDULED
                    ? ['far', 'hourglass']
                    : ['far', 'play']
                }
                iconProps={{
                  className: css.buttonIcon,
                }}
                onClick={this.toggleDigestStatus}
              >
                {digest.status === digestStatus.STATUS_SCHEDULED
                  ? 'Suspend'
                  : 'Resume'}
              </Button>
              <Button
                className={css.button}
                size="small"
                type="neutral"
                icon={['far', 'eye']}
                iconProps={{
                  className: css.buttonIcon,
                }}
                onClick={openPreviewModal(digestId, selectedSpecialtyId)}
              >
                Preview
              </Button>
            </>
          )}
        </div>
        <div>
          {this.state.selectedCohort && (
            <Subtitle4 className={css.actionsNote}>
              * Please note that delete/suspend/preview actions are applied to{' '}
              {digestCohortsLabel[this.state.selectedCohort]} only
            </Subtitle4>
          )}
        </div>
      </div>
    )
  }

  renderInfo() {
    return (
      <div className={css.info}>
        <img
          src={makeAssetURL('images/site/moderator/daily-digest-timeline.png')}
        />
      </div>
    )
  }

  getCohortOptions() {
    const { digests } = this.props
    const specialtyDigests = digests[this.state.selectedSpecialtyId]
    return _.uniq(_.map(specialtyDigests, 'cohort'))
  }

  render() {
    const {
      availableSpecialties,
      availableSpecialtiesIsLoaded,
      userData,
      userDataIsLoaded,
      preselectedSpecialtyId,
      addDigestUpdateIsLoading,
      deleteDigestUpdateIsLoading,
      setDigestOderIsLoading,
    } = this.props

    const { isPanelOpened } = this.state

    if (!availableSpecialtiesIsLoaded || !userDataIsLoaded) {
      return <StarLoader />
    }

    const defaultSpecialty =
      availableSpecialties[preselectedSpecialtyId || userData.specialtyId]

    const cohortSelectOptions = this.getCohortOptions()?.map((cohort) => ({
      label: digestCohortsLabel[cohort],
      value: cohort,
    }))

    return (
      <>
        {!addDigestUpdateIsLoading &&
        !setDigestOderIsLoading &&
        !deleteDigestUpdateIsLoading ? null : (
          <CoverLoader />
        )}
        <Page className={css.page}>
          {!isPanelOpened ? null : (
            <div className={css.contentLeftFiller}></div>
          )}
          <div
            className={classNames({
              [css.content]: true,
              [css.contentPanelOpened]: isPanelOpened,
            })}
          >
            <div className={css.headerContainer}>
              <div className={css.mainHeader}>
                <div className={css.specialtySelectContainer}>
                  <SimpleSelect
                    options={_.map(
                      _.sortBy(availableSpecialties, 'specialty'),
                      (specialty) => ({
                        label: specialty.specialty,
                        value: specialty.specialtyId,
                      })
                    )}
                    defaultValue={{
                      label: defaultSpecialty.specialty,
                      value: defaultSpecialty.specialtyId,
                    }}
                    onChange={this.handleChangeSpecialty}
                    components={{
                      SingleValue: ({ children }) => (
                        <Header2 className={css.containerText}>
                          {children}
                        </Header2>
                      ),
                      DropdownIndicator: SimpleDropdownIndicator,
                    }}
                  />
                </div>
                <Header2 className={css.headerText}>Daily Digest</Header2>
                {Boolean(this.state.selectedCohort) &&
                  Boolean(cohortSelectOptions?.length) && (
                    <div className={css.cohortSelectContainer}>
                      <ContainedSelect
                        options={cohortSelectOptions}
                        value={{
                          value: this.state.selectedCohort,
                          label: digestCohortsLabel[this.state.selectedCohort],
                        }}
                        onChange={this.handleChangeCohort}
                      />
                    </div>
                  )}
              </div>
              {this.renderButtons()}
            </div>
            {this.renderUpdates()}
          </div>
          <QuestionShortTitleModal />
          <EmailPreviewModal />
          <ConfirmationModal
            closeText="Cancel"
            closeType="neutral"
            header="Are you sure you want to remove this update?"
            modalId={DELETE_DIGEST_UPDATE_MODAL.modalId}
            onSubmit={this.handleDeleteUpdate}
            submitText="Delete"
            submitType="destructive"
          />
          <ConfirmationModal
            closeText="Cancel"
            closeType="neutral"
            header="Are you sure you want to remove this question with all its updates?"
            modalId={DELETE_DIGEST_QUESTION_MODAL.modalId}
            onSubmit={this.handleDeleteQuestion}
            submitText="Delete"
            submitType="destructive"
          />
          <ConfirmationModal
            closeText="Cancel"
            closeType="neutral"
            header="Are you sure you want to delete this digest?"
            modalId={DELETE_DIGEST_MODAL.modalId}
            onSubmit={this.handleDelete}
            submitText="Delete"
            submitType="destructive"
          />
          <SplitCohortModal />
        </Page>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchAvailableSpecialtiesRequest = getRequest(
    state,
    FETCH_AVAILABLE_SPECIALTIES
  )
  const fetchTodayDigetRequest = getRequest(state, FETCH_TODAY_DIGEST)
  const addDigestUpdateRequest = getRequest(state, ADD_DIGEST_UPDATE)
  const deleteDigestUpdateRequest = getRequest(state, DELETE_DIGEST_UPDATE)
  const createDigestRequest = getRequest(state, CREATE_DIGEST)
  const deleteDigestRequest = getRequest(state, DELETE_DIGEST)
  const userRequest = getRequest(state, FETCH_USER_WITH_PERMISSIONS)
  const resumeRequest = getRequest(state, RESUME_DIGEST)
  const suspendRequest = getRequest(state, SUSPEND_DIGEST)
  const setDigestOderRequest = getRequest(state, SET_DIGEST_ORDER)
  const splitCohortRequest = getRequest(state, SPLIT_DIGEST_INTO_TWO_COHORTS)
  const mergeCohortsRequest = getRequest(state, MERGE_TODAY_DIGEST_COHORTS)
  const preselectedSpecialtyId = new URLSearchParams(
    ownProps.location.search
  ).get('preselectedSpecialtyId')

  return {
    toggleDigestIsLoading: resumeRequest.isLoading || suspendRequest.isLoading,
    fetchTodayDigestIsLoading: fetchTodayDigetRequest.isLoading,
    addDigestUpdateIsLoading: addDigestUpdateRequest.isLoading,
    deleteDigestUpdateIsLoading: deleteDigestUpdateRequest.isLoading,
    availableSpecialties: state.digest.availableSpecialties,
    availableSpecialtiesIsLoaded: fetchAvailableSpecialtiesRequest.isLoaded,
    createIsLoading: createDigestRequest.isLoading,
    setDigestOderIsLoading: setDigestOderRequest.isLoading,
    splitCohortIsLoading: splitCohortRequest.isLoading,
    mergeCohortsIsLoading: mergeCohortsRequest.isLoading,
    allUpdates: state.questionUpdate.updates,
    allUpdateNotifications: state.questionUpdate.notifications,
    allQuestions: state.question.questions,
    userData: state.user.data || {},
    userDataIsLoaded: userRequest.isLoaded,
    deleteDigestIsLoading: deleteDigestRequest.isLoading,
    digests: state.digest.digests,
    preselectedSpecialtyId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTodayDigest: (specialtyId, callback) =>
      dispatch(fetchTodayDigest(specialtyId, callback)),
    fetchAvailableSpecialties: () => dispatch(fetchAvailableSpecialties()),
    openDeleteModal: () => dispatch(openModal(DELETE_DIGEST_MODAL.modalId)),
    openDeleteUpdateModal: (updateId, questionId) =>
      dispatch(
        openModal(DELETE_DIGEST_UPDATE_MODAL.modalId, { updateId, questionId })
      ),
    openDeleteQuestionModal: (questionId) =>
      dispatch(openModal(DELETE_DIGEST_QUESTION_MODAL.modalId, { questionId })),
    openPreviewModal: (digestId, specialtyId) => () =>
      dispatch(
        openModal(
          EMAIL_PREVIEW_MODAL.modalId,
          { digestId, specialtyId },
          EMAIL_PREVIEW_MODAL.screens.digest
        )
      ),
    openSubjectsPreviewModal: (digestId, specialtyId) => () =>
      dispatch(
        openModal(
          EMAIL_PREVIEW_MODAL.modalId,
          { digestId, specialtyId },
          EMAIL_PREVIEW_MODAL.screens.subjects
        )
      ),
    openSplitCohortModal: (digestId, callback) =>
      dispatch(
        openModal(SPLIT_DIGEST_COHORT_MODAL.modalId, {
          digestId,
          callback,
        })
      ),
    createDigest: (specialtyId) => dispatch(createDigest(specialtyId)),
    deleteDigest: (digestId, callback) =>
      dispatch(deleteDigest(digestId, callback)),
    suspendDigest: (digestId, reason) =>
      dispatch(suspendDigest(digestId, reason)),
    resumeDigest: (digestId) => dispatch(resumeDigest(digestId)),
    addDigestUpdate:
      (specialtyId, digestId, callback) => (updateId, questionId) =>
        dispatch(
          addDigestUpdate(specialtyId, digestId, updateId, questionId, callback)
        ),
    deleteDigestUpdate: (
      specialtyId,
      digestId,
      updateId,
      questionId,
      callback
    ) =>
      dispatch(
        deleteDigestUpdate(
          specialtyId,
          digestId,
          updateId,
          questionId,
          callback
        )
      ),
    fetchNotificationUsage: (specialtyId, questionIds, exclude) =>
      dispatch(fetchNotificationUsage(specialtyId, questionIds, exclude)),
    fetchUpdateInNotificationUsage: (specialtyId, updateIds, exclude) =>
      dispatch(fetchUpdateInNotificationUsage(specialtyId, updateIds, exclude)),
    setDigestOrder: (digestId, questionId, updateIds) =>
      dispatch(setDigestOrder(digestId, questionId, updateIds)),
    setPrimaryTopic: (digestId, questionUpdateId) => (topicId) =>
      dispatch(setPrimaryTopic(digestId, questionUpdateId, topicId)),
    setDigestSubject: (digestId, subject) =>
      dispatch(setDigestSubject(digestId, subject)),
    splitIntoTwoCohorts: (digestId, splitMethod, callback) =>
      dispatch(splitIntoTwoCohorts(digestId, splitMethod, callback)),
    mergeTodayCohorts: (specialtyId, callback) =>
      dispatch(mergeTodayCohorts(specialtyId, callback)),
    setTemplateFlag: (digestId, flag, callback) =>
      dispatch(setTemplateFlag(digestId, flag, callback)),
    setIncludeAskExpertFlag: (digestId, flag, callback) =>
      dispatch(setIncludeAskExpertFlag(digestId, flag, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDigestPage)
